<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expandOnHover="drawer && smallMenu"
    :right="$vuetify.rtl"
    :src="barImage ? barImage: 'a'"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      expand
      nav
    >
      <v-list-item>
        <v-list-item-content>
          <v-img :src='logo'></v-img>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, i) in computedItems">

        <base-item-group
          v-if="getLength(item.children) > 1 || item.children[0].path"
          :key="`group-${i}`"
          :item="item"
        >
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
          :title="item.meta.title"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {mapState} from 'vuex'
  import {constantRoutes} from '@/router/index'
  import store from '@/store'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      logo:require('@/assets/logo9.png'),
      admins: [
        ['Management', 'people_outline'],
        ['Settings', 'settings'],
      ],
      cruds: [
        ['Create', 'add'],
        ['Read', 'insert_drive_file'],
        ['Update', 'update'],
        ['Delete', 'delete'],
      ],
    }),

    computed: {
      ...mapState({
        barColor: state => state.settings.barColor,
        barImage: state => state.settings.barImage,
        smallMenu: state => state.settings.smallMenu
      }),
      drawer: {
        get () {
          return this.$store.state.settings.drawer
        },
        set (val) {
          this.$store.commit('settings/SET_DRAWER', val)
        },
      },
      computedItems () {
        let routes = store.getters['routes/routes']
        let combinedRoutes = constantRoutes.concat(routes)
        return combinedRoutes.filter(x => !x.hidden)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      getLength(item){
        if (item){
          return item.length
        }
        return 0
      }
    },
  }
</script>

<style lang="scss"></style>
